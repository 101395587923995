<template>
  <div class="dashboard">
    <va-card :title="$t('ajuizamento.quitados.title') + ` - ${filteredData.length} operações encontradas`">
      <div class="row align--center mb-1">
        <div class="flex xs12 sm6">
          <va-input
            class="ma-0"
            :value="term"
            :placeholder="$t('tables.searchByName')"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-select
            :options="perPageOptions"
            v-model="perPage"
            label="Itens por página"
          />
        </div>
      </div>

      <va-data-table
        :per-page="perPage"
        :fields="mode ? detailedFields : fields"
        :data="filteredData"
        :loading="loading"
        hoverable
      >

        <template slot="dataUltimaParcelaPaga" slot-scope="props">
          {{new Date(props.rowData.dataUltimaParcelaPaga).toLocaleString('pt-br').substr(0, 10)}}
        </template>
      </va-data-table>
    </va-card>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import negativacoesService from '../../services/Cobranca/NegativacoesService'

export default {
  data () {
    return {
      operacoes: [],
      perPage: 15,
      perPageOptions: ['15', '25', '35', '50', '75', '100', '200', '300', '400', '500'],
      loading: false,
      term: null,
      mode: 0,
    }
  },
  computed: {
    fields () {
      return [{
        name: 'id',
        title: 'EmprestimoId',
      }, {
        name: 'razaoSocial',
        title: 'Razão Social',
      }, {
        name: 'acordoId',
        title: 'AcordoId',
      },
      {
        name: 'valorLiberado',
        title: '$ Liberado',
      },
      {
        name: 'prazo',
        title: 'Prazo',
      },
      {
        name: 'taxa',
        title: 'Taxa',
      },
      {
        name: 'parcelasPagasComAtraso',
        title: 'Parc. Pagas com Atraso',
      },
      {
        name: 'mediaDiasPagamentoFinal',
        title: 'Média Atraso Pgts em dias',
      },
      {
        name: 'diasPagamentoMaisAtrasadoFinal',
        title: 'Maior qtde dias atraso',
      },
      {
        name: '__slot:dataUltimaParcelaPaga',
        title: 'Último pgto',
      }]
    },
    modeOptions () {
      return [{
        value: 0,
        label: this.$t('dashboard.table.brief'),
      }]
    },
    filteredData () {
      if (!this.term || this.term.length < 1) {
        return this.operacoes
      }

      return this.operacoes.filter(item => {
        return item.razaoSocial.toLowerCase().startsWith(this.term.toLowerCase())
      })
    },
  },
  methods: {
    getStatusColor (status) {
      if (status === 'paid') {
        return 'success'
      }

      if (status === 'processing') {
        return 'info'
      }

      return 'danger'
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
  async mounted () {
    this.operacoes = (await negativacoesService.quitados()).data.data
  },
}
</script>
